import { OpenAPIClientAxios } from "openapi-client-axios";
import axiosRetry from "axios-retry";
import definition from "@/assets/docs/api.json";
import { Client } from "@/assets/docs/client.d";

export default {
  install: app => {
    const api = new OpenAPIClientAxios({
      definition: definition,
      axiosConfigDefaults: {
        timeout: 60 * 1000, // Timeout
        withCredentials: true, // Check cross-site Access-Control
      },
    });

    const client = api.initSync<Client>();
    client.defaults.headers.common["Content-Type"] = "application/json";
    client.interceptors.response.use(
      response => response,
      function (error) {
        /*
         * LS
         * 
         * HTTP response 498 Invalid Token is an unofficial client error that is specific to ArcGIS
         * and indicates that the HTTP request included a token, but it was not accepted.
         * See https://http.dev/498
         * 
         * We, GenTwo, use it to instruct the frontend application to redirect to ADB2C.
         * This is because, by-design, frontend will get a CORS error from ADB2C
         * when trying to redirect. So, we need to manually navigate the frontend to ADB2C.
         */
        if(error.status === 498) {
          window.location.href = error.response.data;
          return error.response;
        }

        /*
         * LS
         * 
         * By design, if we get HTTP response 401 Unauthorized
         * this means that we want to refresh the cookies or force a re-login
         */
        if(error.status === 401) {
          let returnUrl = '/';
          if(error.request?.responseURL) {
            returnUrl = new URL(error.request.responseURL).pathname;
          }

          window.location.href = `/apptwo/account/login?return_url=${returnUrl}`;
          return error.response;
        }
        
        return Promise.reject(error);
    });
   
    axiosRetry(client, {
      retries: 3,
      retryDelay: axiosRetry.exponentialDelay,
    });
    
    app.config.globalProperties.$apiClient = client;
  },
};
