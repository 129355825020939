import { defineStore } from "pinia";
import * as Sentry from "@sentry/vue";
import { Paths } from "@/assets/docs/client.d";
import { useAccountStore } from "./account";
import { pinia } from ".";

export interface ApiState {
  options: Paths.GetFeatureFlags.Responses.$200 | {};
  backendClientErrors: Response | null;
}

export const useApiStore = defineStore("ApiStore", {
  state: (): ApiState => ({
    options: {},
    backendClientErrors: null,
  }),
  actions: {
    async init() {
      await this.loadOptions();
    },
    initInterceptors(toast) {
      // Add a response interceptor
      this.apiClient.interceptors.response.use(
        response => response,
        async error => {
          // clear previous messages
          toast.removeAllGroups();

          this.backendClientErrors = null;

          if (error.response) {
            /*
             * LS
             * 
             * By design, if we get HTTP response 401 Unauthorized
             * this means that we want to refresh the cookies or force a re-login
             */
            if(error.status === 401) {
              let returnUrl = '/';
              if(error.request?.responseURL) {
                returnUrl = new URL(error.request.responseURL).pathname;
              }

              window.location.href = `/apptwo/account/login?return_url=${returnUrl}`;
              return error.response;
            }

            // Do something with response error
            if (error.response.data?.title || error.response.data?.detail) {
              // TAH: This is a ProblemDetail message
              toast.add({
                severity: "error",
                summary: error.response.data.title,
                detail: error.response.data.detail,
              });
            } else {
              switch (error.response.status) {
                case 400:
                  if (error.response?.data?.detail) {
                    toast.add({
                      severity: "error",
                      summary: error.response?.data?.title,
                      detail: error.response?.data?.detail,
                    });
                  } else {
                    toast.add({
                      severity: "error",
                      summary: "The input provided was not correct",
                    });
                  }

                  this.backendClientErrors = error.response.data.errors;
                  return error.response;
                case 403:
                  toast.add({
                    severity: "warn",
                    summary:
                      error.response.data.detail ??
                      "You are not allowed to access that section/resource.",
                  });
                  return "You are not allowed to access that section/resource.";
                case 500:
                  const account = useAccountStore(pinia);
                  Sentry.showReportDialog({
                    user: {
                      name: account.user?.firstName + " " + account.user?.lastName,
                      email: account.user?.email,
                    },
                    title: "Sorry, we've had a problem (on our end))",
                    subtitle: "The Gentwo Development team has already been notified.",
                    subtitle2:
                      "If you'd like to help us further, please tell us what happened below. If this is impacting your business, please email dev@g2fp.com or call us on +41 44 512 75 00",
                  });
              }
            }
          }
          else if (error.message === "Network Error") {
            toast.add({
              severity: "warn",
              summary: "The API cannot be reached.",
              detail: "Please check your connection and refresh the page.",
            });
          } else {
            toast.add({
              severity: "error",
              summary: "An error occured during an API call. Please refresh the page.",
            });
          }

          return Promise.reject(error);
        },
      );
    },
    async loadOptions() {
      const response = await this.apiClient.GetFeatureFlags();
      this.options = response?.data || {};
    },
    async clearBackendErrors() {
      this.backendClientErrors = null;
    },
    async loadReleaseNotesPopup(){     
      const response = await this.apiClient(import.meta.env.VITE_STRAPI_BASE_URL + "/api/g2-pro-release-notes-popup");
      return response.data;
    },
    async downloadFile(endpoint, payload, requestBody, url) {
      return endpoint && (payload || requestBody)
        ? this.apiClient[endpoint](payload, requestBody, {
            responseType: "blob",
          })
        : this.apiClient(url, { responseType: "blob" });
    },
  },
});
