export const PartyType = {
  NOT_SET: 0,
  CUSTODIAN: 1,
  BROKER: 2,
  STRATEGY_MANAGER: 3,
  OTHER: 4,
  CRYPTO_EXCHANGE: 5,
  STORAGE_PROVIDER: 6,
  DISTRIBUTION_AGENT: 7,
  LISTING: 8,
  DEAL_INTRODUCER: 9,
  TREASURY_MANDATE_HOLDER: 10,
  INTRODUCER_PROGRAMME: 11,
  INVESTMENT_ADVISOR: 12,
  BORROWER_FINAL: 13,
  PLATFORM_P2_P: 14,
  FUND: 15,
  PRIVATE_EQUITY: 16,
  REFERRAL: 17,
  PAYING_AGENT: 18,
  STRATEGY_ADVISOR: 19,
  INTRODUCING_BROKER: 20,
  BROKER_CUSTODIAN: 21,
  AFFILIATE: 22,
  PRODUCT_SPONSOR: 23,
  PRODUCT_MANAGER: 24,
  SERVICE_PROVIDER_DIGITAL: 25,
  BORROWER_INTERMEDIARY: 26,
  ASSIGNOR: 27,
  ESCROW_AGENT: 28,
  SPV: 29,
  PROSPECT: 30,
  DIRECTOR_OF_ISSUER: 31,
  TRUSTEE: 32,
  SECURITY_AGENT: 33,
  ISSUANCE_AGENT: 34,
  AUTHORIZED_OFFEROR: 35,
  SUB_OFFEROR: 36,
  TRUSTED_THIRD_PARTY: 37,
  PRODUCT_ADMINISTRATOR: 38,
  SELLING_SHAREHOLDER: 39,
  FUND_MANAGER: 40,
  CONTINGENT_BORROWER: 41,
  DIRECTOR: 42,
  MAIN_SHAREHOLDER: 43,
  TRADER: 44,
  PRIMARY_PRODUCT_MANAGER: 51,
  PRIMARY_DISTRIBUTION_AGENT: 52,
};

export const WellKnownPartyIds = {
  IspSecuritiesPartyId: 72,
  SwissAssetizationPartyId: 2096,
  CSC: 2621,
  IspClientId: 469,
  IspAsPS: 967,
  InCorePartyId: 69,
};

export const DirectorIssuerCompanyIds = {
  CSC: 'company::1029'
}

export const ProductState = {
  NotSet: 0,
  Issued: 1,
  InSubscriptionPrimary: 2,
  Matured:3,
  PendingKeyAccount: 4,
  Cancelled: 5,
  Terminated: 6,
  OnHold: 7,
  InSubscriptionSecondary: 9,
  PendingStructuring: 11,
  StructuringDone: 12,
  PendingTelekurs: 13,
  KaDone: 14,
  LegalReview: 15,
  LegalDone: 16,
  PendingCompliance: 17,
  ComplianceDone: 18,
  PendingPayingAgent: 19,
  InTermination: 20,
  PccIncorporation: 21,
  SpcIncorporation: 22,
  Draft: 23,
};

export const MainProductType = {
  STANDARD: 1,
  COMPLEX: 2,
  G2DSTANDARD: 3,
  G2DCOMPLEX: 4,
};
export const ProductType = {
  AMC: "AMC",
  CLN: "CLN",
  TRC: "TRACKERS",
};
export const InternalProductType = {
  NotSet: 0,
  AMCBankable: 1,
  CLNUnsecured: 2,
  TrackerCertificateBankable: 3,
  Other: 4,
  AmcNonBankable: 6,
  AmcHybrid: 7,
  AmcDigital: 8,
  TrackerCertificateNonBankable: 9,
  TrackerCertificateDigital: 10,
  CLNSecured: 11,
  CLNConvertible: 12,
};

export const ProductOffering = {
  PRIVATE_PLACEMENT: 1,
  RETAIL_OFFERING: 2,
  EXEMPTION_RETAIL_OFFERING: 3,
  WHITELABELING_RETAIL_OFFERING: 4,
  US_OFFERING_ACCREDITED_INVESTOR: 5,
  US_OFFERING_QUALIFIED_PURCHASER: 6,
};

export const DocumentType = {
  NOT_SET: 0,
  NDA: 1,
  PASSPORT: 2,
  UTILITY_BILL: 3,
  FINAL_TERMS: 4,
  KID: 5,
  BASE_PROSPECTUS: 6,
  OTHER: 7,
  AUTHORIZED_OFFEROR_AGREEMENT: 9,
  ISSUANCE_AGENT_AGREEMENT: 10,
  COLLATERAL_AGREEMENT: 11,
  CONTROL_AGREEMENT: 12,
  PURCHASE_AGREEMENT: 13,
  SUBSCRIPTION_FORM: 14,
  ESCROW_AGENT_AGREEMENT: 15,
  OTHER_DUE_DILIGENCE: 16,
  INDICATIVE_TERMS: 17,
  DIAGRAM: 18,
  COMMERCIAL_REGISTER_EXTRACT: 19,
  REGULATORY_STATUS: 20,
  CERTIFICATE_OF_INCORPORATION: 21,
  SHAREHOLDER_REGISTER: 22,
  FINANCIAL_STATEMENT: 23,
  DIRECTOR_PA_PRODUCT_TERMINATION_LETTER: 24,
  ACCOUNT_CLOSING_REPORT: 25,
  ADVISORY_FRAMEWORK_AGREEMENT: 26,
  AUTHORIZED_SIGNATORIES: 27,
  BANK_ACCOUNT: 28,
  BUSINESS_DESCRIPTION: 29,
  DESCRIPTION_OF_USE_OF_PROCEED: 30,
  RESOLUTION_TO_APPROVE_LOAN: 31,
  ARTICLE_OF_ASSOCIATION: 32,
  SHAREHOLDERS_AGREEMENT: 33,
  VALUATION_METHODOLOGY_AND_LETTER_OF_APPOINTMENT: 34,
  SHARE_PURCHASE_OR_TRANSFERT_AGREEMENT: 35,
  LOAN_AGREEMENT: 36,
  LEGAL_OPINION: 37,
  FUND_OFFERING_DOCUMENT: 38,
  FUND_FACSHEET: 39,
  DIRECTOR_ACCOUNT_CLOSING_PROOF: 40,
};

export const DocumentTypeLabel = {
  [DocumentType.NOT_SET]: "Not Set",
  [DocumentType.NDA]: "NDA",
  [DocumentType.PASSPORT]: "Passport",
  [DocumentType.UTILITY_BILL]: "Utility Bill",
  [DocumentType.FINAL_TERMS]: "Final Terms",
  [DocumentType.KID]: "KID",
  [DocumentType.BASE_PROSPECTUS]: "Base Prospectus",
  [DocumentType.OTHER]: "Other",
  [DocumentType.AUTHORIZED_OFFEROR_AGREEMENT]: "Authorized Offeror Agreement",
  [DocumentType.ISSUANCE_AGENT_AGREEMENT]: "Issuance Agent Agreement",
  [DocumentType.COLLATERAL_AGREEMENT]: "Collateral Agreement",
  [DocumentType.CONTROL_AGREEMENT]: "Control Agreement",
  [DocumentType.PURCHASE_AGREEMENT]: "Purchase Agreement",
  [DocumentType.SUBSCRIPTION_FORM]: "Subscription Form",
  [DocumentType.ESCROW_AGENT_AGREEMENT]: "Escrow Agent Agreement",
  [DocumentType.OTHER_DUE_DILIGENCE]: "Other Due Diligence",
  [DocumentType.INDICATIVE_TERMS]: "Indicative Terms",
  [DocumentType.DIAGRAM]: "Diagram",
  [DocumentType.COMMERCIAL_REGISTER_EXTRACT]: "Commercial Register Extract",
  [DocumentType.REGULATORY_STATUS]: "Regulatory Status",
  [DocumentType.CERTIFICATE_OF_INCORPORATION]: "Certificate of Incorporation",
  [DocumentType.SHAREHOLDER_REGISTER]: "Shareholder Register",
  [DocumentType.FINANCIAL_STATEMENT]: "Financial Statement",
  [DocumentType.DIRECTOR_PA_PRODUCT_TERMINATION_LETTER]: "Director Product Termination Letter",
  [DocumentType.ACCOUNT_CLOSING_REPORT]: "Account Closing Report",
  [DocumentType.ADVISORY_FRAMEWORK_AGREEMENT]: "Advisory Framework Agreement",
  [DocumentType.AUTHORIZED_SIGNATORIES]: "Authorized Signatories",
  [DocumentType.BANK_ACCOUNT]: "Bank Account",
  [DocumentType.BUSINESS_DESCRIPTION]: "Business Description",
  [DocumentType.DESCRIPTION_OF_USE_OF_PROCEED]: "Description of Use of Proceed",
  [DocumentType.RESOLUTION_TO_APPROVE_LOAN]: "Resolution to Approve Loan",
  [DocumentType.ARTICLE_OF_ASSOCIATION]: "Article of Association",
  [DocumentType.SHAREHOLDERS_AGREEMENT]: "Shareholders Agreement",
  [DocumentType.VALUATION_METHODOLOGY_AND_LETTER_OF_APPOINTMENT]:
    "Valuation Methodology and Letter of Appointment",
  [DocumentType.SHARE_PURCHASE_OR_TRANSFERT_AGREEMENT]: "Share Purchase or Transfert Agreement",
  [DocumentType.LOAN_AGREEMENT]: "Loan Agreement",
  [DocumentType.LEGAL_OPINION]: "Legal Opinion",
  [DocumentType.FUND_OFFERING_DOCUMENT]: "Fund Offering Document",
  [DocumentType.FUND_FACSHEET]: "Fund Facsheet",
  [DocumentType.DIRECTOR_ACCOUNT_CLOSING_PROOF]: "Director Account Closing Proof",
};

export const DATETIME_MIN = "0001-01-01T00:00:00.0000000";

export const KnownUserCompanyIds = {
  Albecq: 2,
  ISP: 3,
};

export const FlowFileActionType = {
  ADD: 0,
};

export const ProductEventTypeEnum = {
  NotSet: 0,
  Coupon: 1,
  Interest: 2,
  PartialOrFullEarlyRedemption: 3,
  LoanInterest: 4,
  LoanDisbursement: 5,
  LoanRepayment: 6,
};

export const ProductEventType = {
  [ProductEventTypeEnum.NotSet]: "Not Set",
  [ProductEventTypeEnum.Coupon]: "Coupon",
  [ProductEventTypeEnum.Interest]: "Interest",
  [ProductEventTypeEnum.PartialOrFullEarlyRedemption]: "PartialOrFullEarlyRedemption",
  [ProductEventTypeEnum.LoanInterest]: "LoanInterest",
  [ProductEventTypeEnum.LoanDisbursement]: "LoanDisbursement",
  [ProductEventTypeEnum.LoanRepayment]: "LoanRepayment",
};

export const ProductEventStatusEnum = {
  NotSet: 1,
  OnHold: 2,
  Paid: 3,
  Missed: 4,
};

export const ProductEventStatus = {
  [ProductEventStatusEnum.NotSet]: "Not Set",
  [ProductEventStatusEnum.OnHold]: "OnHold",
  [ProductEventStatusEnum.Paid]: "Paid",
  [ProductEventStatusEnum.Missed]: "Missed",
};

export const ValuationTypeEnum = {
  NotSet: 0,
  NoValuation: 1,
  Units: 2,
  Percentage: 3,
};